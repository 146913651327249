import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { Button, Divider, Flex, Tooltip } from '@components/common';
import { ContentfulValueProposition } from '@ts/contentful';
import { ImageTitleTextStack } from '@components/contentful';
import { Checkmark } from '@components/icons';
import { Body, Caption, Heading } from '@components/typography';
import { ChecklistFields, ContentfulButtonFields } from '@ts/contentful';
import variables from '@styles/export.module.scss';
import { handelize } from '@utils/strings';
import { useSiteSettings } from '@services/contentful';
import styles from './ValueProposition.module.scss';

type ValuePropositionProps = ContentfulValueProposition & {
	page: string;
};

const ValueProposition = ({
	header,
	subheader,
	description,
	imageStacks,
	checklist: unfilteredChecklist,
	buttons,
	contentType,
	page,
	mobileColumns = 1,
}: ValuePropositionProps) => {
	const { locale } = useRouter();
	const { data: siteSettings } = useSiteSettings();
	const isFreeShippingUS = !Number(siteSettings?.usShippingThreshold);
	let checklist = unfilteredChecklist;
	const hasHeader = (header !== ' ' && !!header) || !!description || !!subheader;

	if (locale === 'en-US' && isFreeShippingUS) {
		checklist = unfilteredChecklist?.map((item) => {
			if (item.text.includes('Free Shipping')) {
				return {
					...item,
					text: 'Free Standard Shipping on All U.S. Orders',
				}
			}
			return item;
		});
	}

	return (
		<Flex
			column
			className={styles.container}
			data-component-type={contentType}
			data-page-type={page}
			{...{ ['data-' + handelize(header)]: '' }}
		>
			{
				hasHeader && (
					<Flex column align='center' className={styles['header']}>
						<Heading tag='h3'>{header}</Heading>
						{subheader && <Heading tag='h5'>{subheader}</Heading>}
						{description && <Body>{description}</Body>}
					</Flex>

				)
			}
			<Flex className={styles.stacks} data-mobile-2-columns={mobileColumns === 2}>
				{imageStacks.map(stack => (
					<ImageTitleTextStack key={stack.heading} {...stack} />
				))}
			</Flex>
			{checklist ? (
				<Flex justify='evenly' gap={4} className={styles.checklist}>
					{checklist.map(({ text, tooltip, tooltipText }: ChecklistFields) => (
						<Flex key={text} align='center' gap={3} className={styles['checklist__item']}>
							<Checkmark color={variables.green3} />
							<Caption>{text}</Caption>
							{tooltip && <Tooltip message={tooltipText || ''} />}
						</Flex>
					))}
				</Flex>
			) : null}

			{buttons ? (
				<Flex align='center' justify='evenly' className={styles.links}>
					{buttons.map(({ text, link }: ContentfulButtonFields, index) => (
						<Fragment key={text}>
							<Button linkStyle href={link}>
								{text}
							</Button>
							{index !== buttons.length - 1 && (
								<Divider color={variables.gray3} style={{ marginInline: '6.4rem' }} />
							)}
						</Fragment>
					))}
				</Flex>
			) : null}
		</Flex>
	);
};

export default ValueProposition;
